import axios from "axios";
import { useEffect, useState } from "react";
import { Send } from "react-feather";
import { useInterval } from "usehooks-ts";
import { useDevFunctions } from "../../utils/Utils";
import FakeMessage from "../FakeMessage/FakeMessage";
import Message from "../Message/Message";
import PuffLoader from "react-spinners/PuffLoader";
import "./MessagesContainer.css";
import VoiceMessageRecorder from "../VoiceMessageRecorder/VoiceMessageRecorder";
import { useAtom } from "jotai";
import { roomDataAtom, userUidAtom, usernameAtom } from "../../utils/Atoms";
import { MESSAGE_DATA } from "../../utils/Interfaces";

const MessagesContainer = () => {
  const [messageText, setMessageText] = useState<string>("");
  const [messages, setMessages] = useState<MESSAGE_DATA[]>();
  const [focus, setFocus] = useState<boolean>(false);
  const [lastMessageCount, setLastMessageCount] = useState<number>(0);
  const [sendingMessage, setSendingMessage] = useState<boolean>(false);
  const [recordingMessage, setRecordingMessage] = useState<boolean>(false);
  const [recordedMessage, setRecordedMessage] = useState<boolean>(false);
  const [audioBlob, setAudioBlob] = useState<any>(null);
  const [loadedMessages, setLoadedMessages] = useState<boolean>(false);

  const [roomData] = useAtom(roomDataAtom);
  const [username] = useAtom(usernameAtom);
  const [userUid] = useAtom(userUidAtom);

  useEffect(() => {
    if (roomData && roomData.id) loadMessages(lastMessageCount);
    // eslint-disable-next-line
  }, [roomData]);

  useEffect(() => {
    // set scroll to max on first load
    resetScroll();
    // eslint-disable-next-line
  }, [loadedMessages]);

  useInterval(() => {
    loadMessages(lastMessageCount);
  }, 5000);

  const roomLoaded = () => {
    if (!roomData) return false;
    return true;
  };

  const resetScroll = () => {
    const wrapper: any = document.querySelector(".messages-wrapper");
    wrapper.scrollTop = 9999999;
  };

  // reset scroll if user is at the bottom of the messages
  // const smartResetScroll = () => {
  //   const wrapper: any = document.querySelector(".messages-wrapper");
  //   const children = wrapper.childNodes;
  //   if (children.length > 0) {
  //     const lastChild = children[children.length - 1];
  //     if (isInViewport(lastChild)) {
  //       resetScroll();
  //     }
  //     lastMessageRef.current = lastChild.innerText;
  //   }
  // };

  // const isInViewport = (elem: any) => {
  //   var bounding = elem.getBoundingClientRect();
  //   return (
  //     bounding.top >= 0 &&
  //     bounding.left >= 0 &&
  //     bounding.bottom <=
  //       (window.innerHeight || document.documentElement.clientHeight) &&
  //     bounding.right <=
  //       (window.innerWidth || document.documentElement.clientWidth)
  //   );
  // };

  const likeMessage = (messageId: string) => {
    if (!roomLoaded()) return;

    const url = useDevFunctions
      ? `http://127.0.0.1:5001/qr-code-app-19379/us-central1/likeMessage`
      : `https://us-central1-qr-code-app-19379.cloudfunctions.net/likeMessage`;

    const requestData = {
      id: roomData?.id,
      messageId: messageId,
    };

    // send load messages request to backend
    axios({
      method: "get",
      url: url,
      params: requestData,
    })
      .then(function (response) {
        // const data = response.data;
      })
      .catch(function (error) {
        console.log("failed to create or fetch account");
        console.log(error);
      });
  };

  const loadMessages = (count: number) => {
    if (!roomLoaded()) return;
    const url = useDevFunctions
      ? `http://localhost:5001/qr-code-app-19379/us-central1/loadMessages`
      : `https://us-central1-qr-code-app-19379.cloudfunctions.net/loadMessages`;

    const requestData = {
      id: roomData?.id,
      lastMessageCount: count,
    };

    // send load messages request to backend
    axios({
      method: "get",
      url: url,
      params: requestData,
    })
      .then(function (response) {
        const data = response.data;
        // update state
        setMessages(data.messages);
        setLoadedMessages(true);
      })
      .catch(function (error) {
        console.log("failed to create or fetch account");
        console.log(error);
      });
  };

  const sendVoiceMessage = async () => {
    if (!roomLoaded() || sendingMessage) return;
    try {
      setSendingMessage(true);
      setMessageText("");
      resetScroll();

      const blob = await fetch(audioBlob).then((response) => response.blob());

      const url = useDevFunctions
        ? `http://127.0.0.1:5001/qr-code-app-19379/us-central1/sendVoiceMessage`
        : `https://us-central1-qr-code-app-19379.cloudfunctions.net/sendVoiceMessage`;

      // Convert blob to Base64
      const base64 = await new Promise((resolve, reject) => {
        const reader = new FileReader() as any;
        reader.onloadend = () => resolve(reader.result.split(",")[1]);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });

      const headers = {
        "Content-Type": "application/json",
      };
      const data = {
        id: roomData?.id,
        uid: userUid,
        audioBlob: base64,
      };

      await axios.post(url, data, { headers });
      // generate random alphanumeric uid
      const id =
        Math.random().toString(36).substring(2, 15) +
        Math.random().toString(36).substring(2, 15);
      const newMessage: MESSAGE_DATA = {
        message: "",
        base64Blob: base64 as string,
        username: username as string,
        messageId: id,
        likes: 0,
        timestamp: new Date().getTime(),
      };
      setMessages([...(messages || []), newMessage]);
      setAudioBlob(null);
      setRecordingMessage(false);
      setRecordedMessage(false);
    } catch (e) {
      console.log(e);
    }
    setSendingMessage(false);
  };

  // send message to database
  const sendMessage = () => {
    if (!userUid || !username || messageText === "" || sendingMessage) return;
    setSendingMessage(true);

    const messageData = {
      message: messageText,
      uid: userUid,
      id: roomData?.id,
    };

    setMessageText("");
    resetScroll();

    const url = useDevFunctions
      ? `http://localhost:5001/qr-code-app-19379/us-central1/sendMessage`
      : `https://us-central1-qr-code-app-19379.cloudfunctions.net/sendMessage`;

    // send request to backend
    axios({
      method: "post",
      url: url,
      params: messageData,
      headers: { "Content-Type": "application/json" },
    })
      .then(function (response) {
        console.log("message sent");
        const newMessage: MESSAGE_DATA = {
          message: messageText,
          username: username,
          likes: 0,
          timestamp: new Date().getTime(),
        };
        setMessages([...(messages || []), newMessage]);
        setSendingMessage(false);
      })
      .catch(function (error) {
        console.log("message failed to send");
        console.log(error);
        setSendingMessage(false);
      });
  };

  const messageWrapperScrolled = (e: any) => {
    const wrapper = e.target;
    const distanceFromTop = wrapper.scrollTop;
    if (distanceFromTop === 0) {
      // loadMessages(lastMessageCount + 50);
      setLastMessageCount(lastMessageCount + 50);
    }
  };

  const renderFakeMessages = () => {
    return [...new Array(10)].map(() => <FakeMessage />);
  };

  const renderMessages = () => {
    if (messages)
      return messages
        .sort((m1, m2) => m1.timestamp - m2.timestamp)
        .map((message: any, index: number) => (
          <Message
            username={message.username}
            message={message.message}
            base64Blob={message.base64Blob}
            timestamp={message.timestamp}
            pending={message.pending !== undefined}
            likeMessage={likeMessage}
            messageId={message.messageId}
            likes={message.likes}
            key={index}
          />
        ));
    else return renderFakeMessages();
  };

  const onKeyDown = (e: any) => {
    if (e.key === "Enter") sendMessage();
    else setMessageText(e.target.value);
  };

  const handleSendButton = () => {
    if (audioBlob === null && messageText.length === 0) {
      // toggle recording message
      if (!recordingMessage) {
        setRecordingMessage(true);
        setRecordedMessage(true);
      } else setRecordingMessage(false);
    } else if (messageText.length === 0 && audioBlob !== null) {
      if (audioBlob) sendVoiceMessage();
    } else sendMessage();
  };

  const renderSendButton = () => {
    if (sendingMessage) {
      return <PuffLoader size={30} color="var(--persian-green)" />;
    } else {
      return <Send />;
    }
  };

  // render text input or voice message recorder display
  const renderInput = () => {
    if (recordingMessage || recordedMessage) {
      return (
        <VoiceMessageRecorder
          recordingMessage={recordingMessage}
          setRecordingMessage={setRecordingMessage}
          recordedMessage={recordedMessage}
          setRecordedMessage={setRecordedMessage}
          audioBlob={audioBlob}
          setAudioBlob={setAudioBlob}
        />
      );
    } else {
      return (
        <input
          className={`message-input fade-in`}
          placeholder="Type Something Cool..."
          value={messageText}
          onKeyDown={onKeyDown}
          onInput={onKeyDown}
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
        />
      );
    }
  };

  return (
    <div className="messages-container">
      <div className="messages-wrapper" onScroll={messageWrapperScrolled}>
        {renderMessages()}
      </div>
      <div
        className={`message-input-wrapper bg-zinc-800 ${focus ? "focus" : ""}`}
      >
        {renderInput()}
        <button className="message-input-button" onClick={handleSendButton}>
          {renderSendButton()}
        </button>
      </div>
    </div>
  );
};

export default MessagesContainer;
