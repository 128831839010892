import "./VoiceMessageRecorder.css";
import { useReactMediaRecorder } from "react-media-recorder";
import { Trash } from "react-feather";
import { useEffect, useState } from "react";
import WaveSurfer from "wavesurfer.js";

/* flow */
// 1. user clicks record button
// 2. user records message and sees a timer that counts up to 15 seconds
// 3. user clicks stop button or timer reaches 15 seconds
// 4. user sees a preview of the message and can click send or re-record
// 5. user clicks send and message is sent to backend

let timeoutVar: any;

const VoiceMessageRecorder = (props: {
  recordingMessage: boolean;
  setRecordingMessage: (recordingMessage: boolean) => void;
  recordedMessage: boolean;
  setRecordedMessage: (recordedMessage: boolean) => void;
  audioBlob: any;
  setAudioBlob: (audioBlob: any) => void;
}) => {
  const { status, startRecording, stopRecording, mediaBlobUrl } =
    useReactMediaRecorder({ video: false });
  const [audioWave, setAudioWave] = useState<any>(null);

  useEffect(() => {
    if (props.recordingMessage) startRecordingMessage();
    else if (props.recordingMessage === false) stopRecordingMessage();
    // eslint-disable-next-line
  }, [props.recordingMessage]);

  useEffect(() => {
    if (mediaBlobUrl) {
      props.setAudioBlob(mediaBlobUrl);
      clearInterval(timeoutVar);
    }
    // eslint-disable-next-line
  }, [mediaBlobUrl]);

  useEffect(() => {
    if (status === "stopped") {
      // delete the old audio
      if (audioWave) audioWave.destroy();
      renderWaves();
    }
    // eslint-disable-next-line
  }, [status]);

  const stopRecordingMessage = () => {
    stopRecording();
    clearTimeout(timeoutVar);
    props.setRecordedMessage(true);
  };

  const startRecordingMessage = () => {
    startRecording();
    clearInterval(timeoutVar);
    const timeout = setTimeout(() => {
      stopRecording();
      props.setRecordingMessage(false);
    }, 15000);
    timeoutVar = timeout;
  };

  const renderWaves = () => {
    const wavesurfer = WaveSurfer.create({
      container: document.querySelector(".audio-display") as any,
      progressColor: "#33b3a0",
      waveColor: "#9CA1B1",
      url: mediaBlobUrl,
      height: "auto",
      interact: true,

      // Set a bar width
      barWidth: 2,
      // Optionally, specify the spacing between bars
      barGap: 1,
      cursorWidth: 0,
      // And the bar radius
      barRadius: 2,
    });

    // play the audio when it's ready
    wavesurfer.on("ready", () => {
      wavesurfer.play();
    });

    // play/pause the audio when it's clicked
    wavesurfer.on("click", () => {
      wavesurfer.playPause();
    });

    setAudioWave(wavesurfer);
  };

  const deleteAudio = () => {
    if (audioWave) audioWave.destroy();
    props.setRecordedMessage(false);
    props.setRecordingMessage(false);
    props.setAudioBlob(null);
  };

  return (
    <div>
      {mediaBlobUrl !== undefined && (
        <Trash className="delete-audio" onClick={deleteAudio} />
      )}
      <div className="audio-display"></div>
      {status === "recording" && <div className="progress-display" />}
    </div>
  );
};

export default VoiceMessageRecorder;
