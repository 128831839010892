import { useEffect, useState } from "react";
import { useDevFunctions } from "../utils/Utils";
import axios from "axios";
import { ROOM_DATA } from "../utils/Interfaces";
import { Link } from "react-router-dom";
import { useAtom } from "jotai";
import { roomDataAtom } from "../utils/Atoms";
import { GridLoader } from "react-spinners";
import { Users } from "react-feather";
import moment from "moment";

const Rooms = () => {
  const [rooms, setRooms] = useState<ROOM_DATA[]>([]);
  const [pending, setPending] = useState<boolean>(false);
  const [, setRoomData] = useAtom(roomDataAtom);

  useEffect(() => {
    setRoomData(null);
    getRooms();
    // eslint-disable-next-line
  }, []);

  const getRooms = async () => {
    setPending(true);
    const url = useDevFunctions
      ? "http://127.0.0.1:5001/qr-code-app-19379/us-central1/getPublicRooms"
      : "https://us-central1-qr-code-app-19379.cloudfunctions.net/getPublicRooms";

    // fetch rooms
    axios({
      method: "get",
      url,
      headers: { "Content-Type": "application/json" },
    })
      .then((response: any) => {
        const data = response.data;
        const rooms = data.rooms;
        setRooms(rooms);
        setPending(false);
      })
      .catch((error: any) => {
        console.error(error);
        setPending(false);
      });
  };

  // set the room data when a room is clicked
  const roomClicked = (room: ROOM_DATA) => {
    setRoomData(room);
  };

  // render logic for individual room
  const renderRoom = (room: ROOM_DATA) => {
    const userCount = Object.keys(room.users).length;
    const timestamp = new Date(room.createdAt.seconds * 1000);
    const maxDescriptionLength = 35;
    const truncatedDescription = room.description.slice(
      0,
      maxDescriptionLength
    );
    return (
      <Link
        to={`/room/${room.id}`}
        onClick={() => roomClicked(room)}
        key={room.id}
        className="flex justify-between items-center py-5 px-4 border border-zinc-800 rounded-md h-[90px] fade-in"
      >
        <div className="flex flex-col">
          <span className="text-lg font-bold text-white text-glandis">
            {room.name}
          </span>
          <span className="text-xs text-gray-400 mb-1">
            Created {moment(timestamp).format("MMMM Do YYYY")}
          </span>
          <p className="text-alto">
            {truncatedDescription}
            {room.description.length > maxDescriptionLength ? "..." : ""}
          </p>
        </div>
        <div className="flex flex-col items-center gap-1 mx-3">
          <Users className="text-glandis" size={20} />
          <span className="text-alto text-sm">{userCount}</span>
        </div>
      </Link>
    );
  };

  // render loading state for rooms
  const renderLoader = () => {
    return (
      <div className="flex flex-col w-full h-full justify-center items-center transform mt-[-5%] gap-5 fade-in">
        <GridLoader 
          size={25} 
          color="var(--persian-green)" 
          margin={10}
        />
        <span className="text-alto text-2xl">Loading Rooms</span>
      </div>
    );
  };

  // render the list of public rooms or a loader
  const renderRoomList = () => {
    if (pending) return renderLoader();
    else
      return (
        <div className="flex flex-col gap-6 fade-in">
          {rooms.map((room: ROOM_DATA) => renderRoom(room))}
        </div>
      );
  };

  return <div className="pt-5 h-full">{renderRoomList()}</div>;
};

export default Rooms;
