import axios from "axios";
import { useState } from "react";
import { Copy, HelpCircle } from "react-feather";
import QRCode from "react-qr-code";
import { GridLoader } from "react-spinners";
import { useDevFunctions } from "../utils/Utils";
import {
  Tabs,
  TabsList,
  TabsTrigger,
} from "src/components/@/components/ui/tabs";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "src/components/@/components/ui/tooltip";
import { Modal } from "@mui/material";

const CreateRoom = () => {
  const [roomName, setRoomName] = useState<string>("");
  const [roomDescription, setRoomDescription] = useState<string>("");
  const [pending, setPending] = useState<boolean>(false);
  const [newRoomId, setNewRoomId] = useState<string>();
  const [activeTab, setActiveTab] = useState<"public" | "private">("public");
  const [showFullScreenCode, setShowFullScreenCode] = useState<boolean>(false);
  const host = window.location.host;

  const renderInput = (
    label: string,
    placeholder: string,
    value: string,
    onChange: (val: string) => void
  ) => {
    return (
      <div className="flex flex-col ">
        <span className="mb-2">{label}</span>
        <input
          className="p-2 h-10 rounded-md bg-zinc-800"
          placeholder={placeholder}
          value={value}
          onChange={(e) => onChange(e.target.value)}
          required
        />
      </div>
    );
  };

  const renderVisibilitySwitch = () => {
    const triggerStyle = `h-full! w-1/2`;
    // update the active tab
    const tabClicked = (newActiveTab: "public" | "private") => {
      setActiveTab(newActiveTab);
    };
    // get the text for the tooltip
    const getVisibilityTooltip = () => {
      if (activeTab === "public")
        return "Public rooms can be seen on the public rooms page";
      else return "Private rooms cannot be seen on the public rooms page";
    };
    return (
      <div className="flex flex-col">
        <div className="flex gap-1 items-center">
          <span className="mb-3">
            <span>Visibility</span> 
          </span>
          <span className="text-xs">
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger asChild>
                  <HelpCircle size={15} className="transform -translate-y-[4px]" />
                </TooltipTrigger>
                <TooltipContent>
                  <span>{getVisibilityTooltip()}</span>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </span>
        </div>
        <Tabs defaultValue="public">
          <TabsList className="bg-zinc-800 w-full py-5 px-2 gap-2">
            <TabsTrigger
              className={triggerStyle}
              value="public"
              onClick={() => tabClicked("public")}
            >
              Public
            </TabsTrigger>
            <TabsTrigger
              className={triggerStyle}
              value="private"
              onClick={() => tabClicked("private")}
            >
              Private
            </TabsTrigger>
          </TabsList>
        </Tabs>
      </div>
    );
  };

  const renderInputsContainer = () => {
    return (
      <div className="flex flex-col gap-4">
        {renderInput(
          "Room Name",
          "Give your room a cool name",
          roomName,
          setRoomName
        )}
        {renderInput(
          "Room Description",
          "What should people talk about?",
          roomDescription,
          setRoomDescription
        )}
        {renderVisibilitySwitch()}
      </div>
    );
  };

  const createRoom = async (e: React.FormEvent<HTMLFormElement>) => {
    // prevent refresh
    e.preventDefault();
    setPending(true);
    const url = useDevFunctions
      ? "http://127.0.0.1:5001/qr-code-app-19379/us-central1/createRoom"
      : "https://us-central1-qr-code-app-19379.cloudfunctions.net/createRoom";
    const newRoomData = {
      name: roomName,
      description: roomDescription,
      isPrivate: activeTab === "private",
    };
    // send request to backend
    axios({
      method: "post",
      url: url,
      params: newRoomData,
      headers: { "Content-Type": "application/json" },
    }).then((response: any) => {
      const roomId = response.data.id;
      setNewRoomId(roomId);
      setPending(false);
    });
  };

  const renderForm = () => {
    return (
      <>
        <div>
          <span className="text-lg font-bold text-persian-green">
            Create Your Own Room
          </span>
          <p className="font-light">
            Create a room you and your friends to chat in!
          </p>
        </div>
        <form className="mt-3" onSubmit={(e) => createRoom(e)}>
          {renderInputsContainer()}
          <button className="bg-persian-green hover:opacity-80 text-white font-bold py-2 px-4 rounded mt-4 w-full mt-5">
            Create Room
          </button>
        </form>
      </>
    );
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(`${host}/room/${newRoomId}`);
  };

  const renderFullScreenCode = () => {
    if (!showFullScreenCode) return;
    return (
      <Modal
        open={showFullScreenCode}
        onClose={() => setShowFullScreenCode(false)}
        className="modal"
      >
        <div className="flex justify-center items-center fade-in p-2 bg-almost-black">
          <QRCode value={`${host}/room/${newRoomId}`} size={250} />
        </div>
      </Modal>
    );
  };

  const renderNewRoomData = () => {
    return (
      <div className="flex flex-col gap-2">
        <span className="text-lg font-bold text-persian-green">
          Your new room has been created!
        </span>
        <div className="flex">
          <QRCode
            value={`${host}/room/${newRoomId}`}
            className="room-qr-code"
            size={120}
            onClick={() => setShowFullScreenCode(true)}
          />
          <div className="flex flex-col justify-center">
            <span className="invitation-text-wrapper">
              Invite your friends by sharing this QR code. Save this link
              somewhere! You can also access it when you go to the room.
            </span>
          </div>
        </div>
        <div
          className="flex gap-3 items-center border border-zinc-700 rounded-md p-2 justify-center"
          onClick={() => copyToClipboard()}
        >
          <Copy className="cursor-pointer hover:opacity-80" />
          <span>
            {host}/room/{newRoomId}
          </span>
        </div>
        <a
          className="bg-persian-green hover:opacity-80 text-white font-bold py-2 px-4 rounded w-full mt-2 text-center"
          href={`/room/${newRoomId}`}
        >
          Go To New Room
        </a>
      </div>
    );
  };

  const renderLogic = () => {
    if (newRoomId !== undefined) return renderNewRoomData();
    else if (pending)
      return (
        <div className="flex flex-col gap-3 justify-center items-center h-40">
          <GridLoader size={20} color="var(--persian-green)" />
          <span>Creating Room</span>
        </div>
      );
    else return renderForm();
  };

  return (
    <div className="w-full h-auto max-w-[90%] md:max-w-[500px] bg-almost-black p-4 rounded-md fade-in text-stone-200 shadow-xl">
      {renderFullScreenCode()}
      {renderLogic()}
    </div>
  );
};

export default CreateRoom;
