import "./Message.css";
import moment from "moment";
import { ThumbsUp } from "react-feather";
import { useEffect, useState } from "react";
import ConfettiExplosion from "react-confetti-explosion";
import WaveSurfer from "wavesurfer.js";

const Message = (props: {
  username: string;
  message: string;
  timestamp: number;
  likes: number;
  messageId: string;
  base64Blob?: string;
  likeMessage: (messageId: string) => void;
  pending?: boolean;
}) => {
  const [likes, setLikes] = useState<number>(props.likes || 0);
  const [liked, setLiked] = useState<boolean>(false);
  const [isExploding, setIsExploding] = useState<boolean[]>(
    [...new Array(20)].map(() => false)
  );
  const [audioSource, setAudioSource] = useState<any>(undefined);
  const [audioWave, setAudioWave] = useState<any>(undefined);

  useEffect(() => {
    if (props.base64Blob !== undefined && props.base64Blob !== undefined)
      decodeAudio();
    // eslint-disable-next-line
  }, [props.base64Blob]);

  useEffect(() => {
    if (audioSource !== undefined) renderWaves();
    // eslint-disable-next-line
  }, [audioSource]);

  const decodeAudio = () => {
    const byteCharacters = atob(props.base64Blob || "");

    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);
      const byteNumbers = new Array(slice.length);

      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: "audio/wav" });
    const audioURL = URL.createObjectURL(blob);
    setAudioSource(audioURL);
  };

  const renderWaves = () => {
    if (audioWave) audioWave.destroy();
    try {
      const wavesurfer = WaveSurfer.create({
        container: document.querySelector(
          `.message-audio-display.${formatMessageId()}`
        ) as any,
        progressColor: "#33b3a0",
        waveColor: "#9CA1B1",
        url: audioSource,
        height: "auto",
        interact: true,
        cursorWidth: 0,

        // Set a bar width
        barWidth: 2,
        // Optionally, specify the spacing between bars
        barGap: 1,
        // And the bar radius
        barRadius: 2,
        barHeight: 1,
      });

      wavesurfer.on("ready", () => {
        setAudioWave(wavesurfer);
      });

      // play/pause the audio when it's clicked
      wavesurfer.on("click", () => {
        wavesurfer.playPause();
      });
    } catch (e) {
      console.log(e);
    }
  };

  const formatTimestamp = (timestamp: number) => {
    const formattedTimestamp = moment(timestamp).format("MMM. D, YYYY h:mm a");
    return <div>{formattedTimestamp}</div>;
  };

  const renderConfetti = () => {
    return isExploding.map((isActive: boolean, index: number) => {
      if (isActive)
        return (
          <ConfettiExplosion
            key={index}
            // on complete set the index to false
            onComplete={() => {
              const newIsExploding = [...isExploding];
              newIsExploding[index] = false;
              setIsExploding(newIsExploding);
            }}
            zIndex={100}
            // generate random value between 0 and 0.5 for force
            force={0.1 + Math.random() * 0.6}
          />
        );
      else return null;
    });
  };

  const renderLikes = () => {
    return (
      <div
        className={`message-likes-wrapper`}
        onClick={() => {
          setLikes(likes + 1);
          setLiked(true);
          props.likeMessage(props.messageId);
          // find the first index that is not exploding
          const index = isExploding.findIndex(
            (isExploding: boolean) => !isExploding
          );
          if (index !== -1) {
            const newIsExploding = [...isExploding];
            newIsExploding[index] = true;
            setIsExploding(newIsExploding);
          }
        }}
      >
        {likes > 0 && (
          <span className={`message-like-count`}>{likes || 0}</span>
        )}
        <ThumbsUp className={`${liked ? "liked" : ""}`} />
        {renderConfetti()}
      </div>
    );
  };

  const renderMessage = () => {
    if (props.base64Blob === undefined)
      return <span className="message-message">{props.message}</span>;
    else if (audioSource === undefined)
      return <div className="message-audio-loading">loading audio</div>;
    else return null;
  };

  const formatMessageId = () => {
    // ex messageId: 998db063e652d2bacb1b -> 998db063e652d2bacb1b
    // find the first letter in message ID
    if (props.messageId === undefined) return "";
    const index = props.messageId.search(/[a-zA-Z]/);
    // return a new message ID that starts from the first letter
    return props.messageId.substring(index);
  };

  return (
    <div
      className={`message-wrapper fade-in ${props.pending ? "pending" : ""}`}
    >
      <div className="message-metadata-wrapper">
        <span className={`message-username`}>{props.username}</span>
        <span className="message-timestamp">
          {formatTimestamp(props.timestamp)}
        </span>
      </div>
      <div
        className={`message-audio-display ${formatMessageId()} ${
          audioWave !== undefined ? "" : "hidden"
        }`}
      />
      {renderMessage()}
      {renderLikes()}
    </div>
  );
};

export default Message;
