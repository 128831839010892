import { useAtom } from "jotai";
import {
  isSidebarOpenAtom,
  navbarTitleAtom,
  roomDataAtom,
} from "../utils/Atoms";
import { useEffect, useState } from "react";
import { Menu } from "react-feather";
import { renderLazyLoad } from "../utils/Utils";

const Navbar = () => {
  const [hasClickedNavbar, setHasClickedNavbar] = useState<boolean>(false);
  const [navbarTitle, setNavbarTitle] = useAtom(navbarTitleAtom);
  const [, setIsSidebarOpen] = useAtom(isSidebarOpenAtom);
  const [roomData] = useAtom(roomDataAtom);

  useEffect(() => {
    if (roomData) {
      // set the navbar title to the room name
      setNavbarTitle(roomData.name);
    } else {
      // if there is no room data, set the navbar title to "Public Rooms"
      setNavbarTitle("Public Rooms");
    }
    // eslint-disable-next-line
  }, [roomData]);

  const renderNotificationAlert = () => {
    return (
      <div
        className={`w-2 h-2 rounded-full bg-persian-green absolute top-0 right-[-2px] animate-ping ${
          hasClickedNavbar ? "hidden" : ""
        }`}
      />
    );
  };

  const menuClicked = () => {
    setHasClickedNavbar(true);
    setIsSidebarOpen(true);
  };

  return (
    <div className="flex justify-between">
      <h1 className="text-persian-green font-bold">
        {renderLazyLoad(navbarTitle !== undefined, navbarTitle)}
      </h1>
      <div onClick={menuClicked} className="relative">
        {renderNotificationAlert()}
        <Menu className="text-alto cursor-pointer" />
      </div>
    </div>
  );
};

export default Navbar;
