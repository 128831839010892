import React, { useEffect } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Home from "./pages/Home/Home";
import { signInAnonymously } from "firebase/auth";
import Room from "./pages/Room/Room";
import { auth } from "./utils/Firebase";
import "./index.css";
import Rooms from "./pages/Rooms";
import Navbar from "./components/Navbar";
import Sidebar from "./components/Sidebar";
import axios from "axios";
import { useDevFunctions } from "./utils/Utils";
import ConfettiExplosion from "react-confetti-explosion";

const wrapper = (children: any) => {
  return (
    <div className="h-full w-full">
      <Navbar />
      <Sidebar />
      {children}
    </div>
  );
};

const router = createBrowserRouter([
  {
    path: "/",
    element: wrapper(<Home />),
  },
  {
    path: "room/*",
    element: wrapper(<Room />),
  },
  {
    path: "/rooms/*",
    element: wrapper(<Rooms />),
  },
]);

const App = () => {
  useEffect(() => {
    anonymouseLogin();
    anonymousAnalytics();
    // eslint-disabled-next-line
  }, []);

  const anonymousAnalytics = () => {
    const url = useDevFunctions
      ? "http://localhost:5001/qr-code-app-19379/us-central1/anonymousAnalytics"
      : "https://us-central1-qr-code-app-19379.cloudfunctions.net/anonymousAnalytics";

    // use local storage to keep track of unique visitors
    const hasVisited = localStorage.getItem("hasVisited");
    let isUniqueVisitor = hasVisited === "true" ? false : true;
    // don't store analytics if on localhost
    if (window.location.hostname === "localhost") {
      return;
    }

    const data = {
      isUniqueVisitor: Boolean(isUniqueVisitor),
    };

    // make request
    axios({
      method: "post",
      url: url,
      headers: { "Content-Type": "application/json" },
      params: data,
    })
      .then((response: any) => {
        // update localstorage
        localStorage.setItem("hasVisited", "true");
      })
      .catch((error: any) => {
        console.error(error);
        // update localstorage
        localStorage.setItem("hasVisited", "true");
      });
  };

  // show a blast of confetti when the user joins for the first time
  const renderConfetti = () => {
    const hasVisited = localStorage.getItem("hasVisited");
    if (hasVisited === "true") return;
    return (
      <ConfettiExplosion
        key={0}
        // on complete set the index to false
        zIndex={100}
        // generate random value between 0 and 0.5 for force
        force={-1}
        particleCount={150}
      />
    );
  };

  const anonymouseLogin = () => {
    signInAnonymously(auth)
      .then((data: any) => {
        // Signed in..
      })
      .catch(() => {
        // const errorCode = error.code;
        // const errorMessage = error.message;
        // ...
      });
  };

  return (
    <div className="app-wrapper fade-in relative px-4 pt-5 pb-6 md:px-6 md:pb-1 bg-zinc-900">
      {renderConfetti()}
      <RouterProvider router={router} />
    </div>
  );
};

export default App;
