import "./RoomInfo.css";
import QRCode from "react-qr-code";
import { renderLazyLoad } from "../../utils/Utils";
import { useAtom } from "jotai";
import { roomDataAtom } from "../../utils/Atoms";
import { Copy } from "react-feather";
import { useState } from "react";
import { Modal } from "@mui/material";

const RoomInfo = () => {
  const [roomData] = useAtom(roomDataAtom);
  const [showFullScreenCode, setShowFullScreenCode] = useState<boolean>(false);

  const host = window.location.host;
  const copyToClipboard = () => {
    // copy link to clipboard
    navigator.clipboard.writeText(`${host}/room/${roomData?.id}`);
  };
  
  const renderFullScreenCode = () => {
    if (!showFullScreenCode) return;
    return (
      <Modal
        open={showFullScreenCode}
        onClose={() => setShowFullScreenCode(false)}
        className="modal"
      >
        <div className="flex justify-center items-center fade-in p-2 bg-almost-black">
          <QRCode
            value={`${host}/room/${roomData?.id}`}
            size={250}
          />
        </div>
      </Modal>
    )
  }

  return (
    <div className="room-info-wrapper fade-in p-4">
      {renderFullScreenCode()}
      <div className="mb-5 flex flex-col"> 
        <span className="room-name">
          {renderLazyLoad(roomData?.name !== undefined, roomData?.name)}
        </span>
        <span className="room-description">
          {renderLazyLoad(roomData?.name !== undefined, roomData?.description)}
        </span>
      </div>
      <div className="room-qr-code-wrapper">
        <QRCode
          value={`${host}/room/${roomData?.id}`}
          className="room-qr-code"
          onClick={() => setShowFullScreenCode(true)}
          size={120}
        />
        <div className="">
          <span className="invitation-text-wrapper">
            Invite your friends by sharing this QR code.
          </span>
        </div>
      </div>
      <div
        className="flex gap-3 items-center border border-zinc-700 rounded-md p-2 justify-center text-stone-200 mt-2"
        onClick={() => copyToClipboard()}
      >
        <Copy className="cursor-pointer hover:opacity-80" />
        <span>
          {host}/room/{roomData?.id}
        </span>
      </div>
    </div>
  );
};

export default RoomInfo;
