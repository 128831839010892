import { getFirestore } from "firebase/firestore";
import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
import { getAuth } from "firebase/auth";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyA-GRnAkwdWYijuPKsG5X61Ellf3HwxchI",
  authDomain: "qr-code-app-19379.firebaseapp.com",
  projectId: "qr-code-app-19379",
  storageBucket: "qr-code-app-19379.appspot.com",
  messagingSenderId: "889745982410",
  appId: "1:889745982410:web:61025af0a7a5e6d0c54533",
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);

// Initialize Cloud Firestore and get a reference to the service
export const db = getFirestore(app);

// Initialize Cloud Storage and get a reference to the service
export const storage = getStorage(app);

export const auth = getAuth(app);
