import { useAtom } from "jotai";
import { isSidebarOpenAtom, roomDataAtom } from "../utils/Atoms";
import { List, PlusCircle, X } from "react-feather";
import RoomInfo from "./RoomInfo/RoomInfo";
import { useState } from "react";
import { Modal } from "@mui/material";
import CreateRoom from "./CreateRoom";
import { Link } from "react-router-dom";

const Sidebar = () => {
  const [createRoomModalOpen, setCreateRoomModalOpen] =
    useState<boolean>(false);
  const [isSidebarOpen, setIsSidebarOpen] = useAtom(isSidebarOpenAtom);
  const [roomData] = useAtom(roomDataAtom);
  const sidebarStyle = isSidebarOpen ? "w-full md:w-1/2" : "w-0";

  const renderRoomCreationModal = () => {
    return (
      <Modal
        open={createRoomModalOpen}
        onClose={() => setCreateRoomModalOpen(false)}
        className="modal"
      >
        <CreateRoom />
      </Modal>
    );
  };

  const renderMenuOptions = () => {
    const buttonStyle = 
      `text-persian-green font-bold h-10 text-left 
      flex border border-persian-green rounded-md p-2 items-center
      gap-2 cursor-pointer hover:bg-zinc-700 transition-all`;
    return (
      <div className="flex flex-col gap-3">
        <button
          className={buttonStyle}
          onClick={() => setCreateRoomModalOpen(true)}
        >
          <PlusCircle className="mr-1" />
          <span>Create Your Own Room</span>
        </button>
        <Link 
          to="/rooms" 
          className={buttonStyle}
          onClick={() => setIsSidebarOpen(false)}
        >
          <List className="mr-1" />
          <span>View Public Rooms</span>
        </Link>
      </div>
    );
  };

  const renderIntro = () => {
    return (
      <div className="text-alto flex flex-col gap-3">
        <h1 className="text-lg font-bold">Welcome to Pondr.land!</h1>
        <p className="text-sm">
          Pondr.land is a neutral space for people to chat. There aren't really
          any rules for what you can talk about but keep it reasonable.
        </p>
        <p className="text-sm">
          Every user is given a unique name, and no signup is required to start
          chatting with people.
        </p>
      </div>
    );
  };

  const renderRoomInfo = () => {
    if (roomData) {
      return <RoomInfo />;
    } else {
      return null;
    }
  };

  return (
    <div
      className={`absolute right-0 top-0 bg-zinc-800 h-full overflow-hidden z-10 transition-all flex flex-col justify-between shadow-xl z-50 ${sidebarStyle}`}
    >
      <X
        className="text-alto absolute right-4 top-5 cursor-pointer z-10"
        onClick={() => setIsSidebarOpen(false)}
      />
      <div className="px-4 flex flex-col gap-4 py-5 relative">
        {renderIntro()}
        {renderMenuOptions()}
      </div>
      {renderRoomInfo()}
      {renderRoomCreationModal()}
    </div>
  );
};

export default Sidebar;
