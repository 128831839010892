import { Modal } from "@mui/material";
import axios from "axios";
import { onAuthStateChanged } from "firebase/auth";
import { useEffect, useState } from "react";
import MessagesContainer from "../../components/MessagesContainer/MessagesContainer";
import RoomInfo from "../../components/RoomInfo/RoomInfo";
import { auth } from "../../utils/Firebase";
import { useDevFunctions } from "../../utils/Utils";
import "./Room.css";
import { useAtom } from "jotai";
import { roomDataAtom, userUidAtom, usernameAtom } from "../../utils/Atoms";
import { ROOM_DATA } from "../../utils/Interfaces";
import CreateRoom from "../../components/CreateRoom";

const Room = () => {
  const [createRoomModalOpen, setCreateRoomModalOpen] =
    useState<boolean>(false);
  const [showRoomInfo, setShowRoomInfo] = useState<boolean>(false);
  const [userUid, setUserUid] = useAtom(userUidAtom);
  const [username, setUsername] = useAtom(usernameAtom);
  const [roomData, setRoomData] = useAtom(roomDataAtom);

  useEffect(() => {
    // const path = window.location.pathname;
    // // get the last part of the path
    // const pathData = path.split("/");
    // const roomId = pathData[pathData.length - 1];
    // if (roomId === "a616a5fe") {
    //   // redirect to 9ccd1ac7
    //   window.location.href = "room/9ccd1ac7";
    // }
    getRoomId();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    loadRoomData();
    // eslint-disable-next-line
  }, [userUid]);

  const getRoomId = () => {
    const path = window.location.pathname;
    const pathData = path.split("/");
    const id = pathData[pathData.length - 1];
    // setRoomId(id);
    setRoomData({
      ...(roomData as ROOM_DATA),
      id: id,
    });
    getUserId();
  };

  const loadRoomData = () => {
    if (!userUid || !roomData || !roomData.id) return;

    const url = useDevFunctions
      ? `http://localhost:5001/qr-code-app-19379/us-central1/loadRoomData`
      : `https://us-central1-qr-code-app-19379.cloudfunctions.net/loadRoomData`;

    const signupData = {
      id: roomData.id,
      uid: userUid,
    };

    // send signup request to backend
    axios({
      method: "get",
      url: url,
      params: signupData,
      // headers: { "Content-Type": "application/json"}
    })
      .then(function (response) {
        console.log("loaded account data");

        // set state variables
        if (!username) {
          const data: any = response.data;
          setRoomData({
            ...(roomData as ROOM_DATA),
            name: data.name,
            description: data.description,
          });
          setUsername(data.username);
        }
      })
      .catch(function (error) {
        console.log("failed to load account");
        console.log(error);
      });
  };

  const getUserId = () => {
    onAuthStateChanged(auth, (user: any) => {
      if (user) {
        // get uid
        const uid = user.uid;

        if (!username) {
          loadRoomData();
        }

        // update uid state var
        setUserUid(uid);
      } else {
        // User is signed out
        // ...
      }
    });
  };

  const renderRoomCreationModal = () => {
    return (
      <Modal
        open={createRoomModalOpen}
        onClose={() => setCreateRoomModalOpen(false)}
        className="modal"
      >
        <CreateRoom />
      </Modal>
    );
  };

  return (
    <div className="room-wrapper fade-in">
      <Modal
        open={showRoomInfo}
        onClose={() => setShowRoomInfo(false)}
        className="modal"
      >
        <RoomInfo />
      </Modal>
      {renderRoomCreationModal()}
      <div className="flex justify-between text-persian-green mb-5 shadown-lg"></div>
      <MessagesContainer />
    </div>
  );
};

export default Room;
