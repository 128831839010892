import { useEffect } from 'react';
import './Home.css';
import { useNavigate } from "react-router-dom";

function Home() {

  const navigate = useNavigate();

  useEffect(() => {
    // navigate to default room when landing on home page
    const defaultRoom = "9ccd1ac7";
    if (window.location.pathname.indexOf(defaultRoom) === -1) 
      navigate(`/rooms`);
    // eslint-disable-next-line
  }, []);

  return (
    <div className="home-wrapper">
      <p className="site-description">
        welcome
      </p>
    </div>
  );

}

export default Home;
