import { Skeleton } from "@mui/material";
import { LoremIpsum } from "lorem-ipsum";

const host = window.location.hostname;
export const useDevFunctions = host === "localhost";
if (useDevFunctions) console.log("Using dev functions");

// setup lorem ipsum config
export const loremIpsum = new LoremIpsum({
  sentencesPerParagraph: {
    max: 8,
    min: 4
  },
  wordsPerSentence: {
    max: 10,
    min: 4
  }
});

export function shortenAddress(str: string) {
  return str.substring(0, 6) + '...' + str.substring(str.length - 4)
}

export const renderLazyLoad = (renderObject: boolean, element: any) => {
  if (!renderObject) 
    return (
      <Skeleton
        className="loading-skeleton"
        height={30}
      />
    )
  else return (element);
}

// generate random set of words
export const generateWords = (count: number) => {
  return loremIpsum.generateWords(count);
}

// generate random set of sentences
export const generateSentences = (count: number) => {
  return loremIpsum.generateSentences(count);
}