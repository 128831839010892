import { atom } from "jotai";
import { ROOM_DATA } from "./Interfaces";

// the data for the current room
export const roomDataAtom = atom<ROOM_DATA | null>(null);
// the username of the current user
export const usernameAtom = atom<string | null>(null);
// the uid of the current user
export const userUidAtom = atom<string | null>(null);
// the title of the navbar
export const navbarTitleAtom = atom<string | null>(null);
// the state of the sidebar
export const isSidebarOpenAtom = atom<boolean>(false);
