import { Skeleton } from "@mui/material";
import "./FakeMessage.css";

const FakeMessage = () => {

  // calculate a random width for the skeleton
  const calculateRandomWidth = (min: number, max: number) =>
    `${Math.floor(Math.random() * (max - min + 1) + min)}%`;

  return (
    <div className="fake-message-wrapper fade-in">
      <div className="fake-message-username">
        <Skeleton
          sx={{
            backgroundColor: "rgba(255, 255, 255, 0.2)",
          }}
          height="100%"
          width={calculateRandomWidth(30, 40)}
        />
      </div>
      <div className="fake-message-text">
        <Skeleton
          sx={{
            backgroundColor: "rgba(255, 255, 255, 0.1)",
          }}
          height="100%"
          width={calculateRandomWidth(50, 60)}
        />
      </div>
    </div>
  );
};

export default FakeMessage;
